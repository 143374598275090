// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-en-js": () => import("./../src/pages/blog.en.js" /* webpackChunkName: "component---src-pages-blog-en-js" */),
  "component---src-pages-blog-es-js": () => import("./../src/pages/blog.es.js" /* webpackChunkName: "component---src-pages-blog-es-js" */),
  "component---src-pages-contact-en-js": () => import("./../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contact-es-js": () => import("./../src/pages/contact.es.js" /* webpackChunkName: "component---src-pages-contact-es-js" */),
  "component---src-pages-cv-en-js": () => import("./../src/pages/cv.en.js" /* webpackChunkName: "component---src-pages-cv-en-js" */),
  "component---src-pages-cv-es-js": () => import("./../src/pages/cv.es.js" /* webpackChunkName: "component---src-pages-cv-es-js" */),
  "component---src-pages-dev-en-js": () => import("./../src/pages/dev.en.js" /* webpackChunkName: "component---src-pages-dev-en-js" */),
  "component---src-pages-index-en-js": () => import("./../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("./../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-en-js": () => import("./../src/pages/projects.en.js" /* webpackChunkName: "component---src-pages-projects-en-js" */),
  "component---src-pages-projects-es-js": () => import("./../src/pages/projects.es.js" /* webpackChunkName: "component---src-pages-projects-es-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-dev-post-js": () => import("./../src/templates/devPost.js" /* webpackChunkName: "component---src-templates-dev-post-js" */)
}

